.abaris-nav .navbar.navbarItem {
    box-shadow: none;
    padding: 0;
    border-radius: 0;
}

header.sticky {
    transform: translateY(0px);
    background: #fff;
    position: relative;
    z-index: 1;
}

header.sticky.active {
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 11;
    transform: translateY(-42px);
    transition: .4s ease-in-out;
    box-shadow: 0px 15px 10px -15px #6e6e6eb8;
}

@media screen and (max-width: 768px) {
    header.sticky.active {
        top: -88px;
    }
}

.navbarArea {
    border-top: 1px solid #f2f2f2;
}

.allCategoryDrop {
    padding: 7px 10px;
    background: #efefef;
    width: 260px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    display: none;
    cursor: pointer;
}

.menuCard {
    display: flex;
    align-items: center;
}

.allCategoryDrop i {
    font-size: 20px;
}

.bannerContent .title p {
    color: #626262;
}

.categoryList {
    position: absolute;
    top: 44px;
    left: -350px;
    z-index: -111;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .4s ease-in-out;
}

.categoryList ul {
    margin: 0;
    padding: 10px;
    background: #fff;
}

.categoryList ul li a {
    text-transform: capitalize;
    color: #333333;
    line-height: 33px;
    display: block;
    transition: .4s ease-in-out;
}

.allCategoryDrop:hover .categoryList {
    opacity: 1;
    left: 0px;
    z-index: 111;
    position: absolute;
}

.categoryList ul li a:hover {
    color: #6b0033;
}